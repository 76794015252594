import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
//import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';

import Page from '../components/Page';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';

const validationSchema = yup.object({
  currentPassword: yup.string().required('Please specify your password'),
  newPassword: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
  repeatPassword: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
});

const Billing = () => {
  const theme = useTheme();
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Main>
      <Page>
        <Box>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Typography variant="h6">
              <Typography variant="h6" fontWeight={700}>

               We reside in India and India resides in us Jai Hind<br />
              </Typography>
              <Typography align="justify">
                <br />Independence Day and Onam was celebrated on Wednesday, 18 th August 2021, as the event of the month. Independence Day is celebrated on 15 th August every year with great patriotic fervour which marks the rising of free India. The celebrations commenced with the flag hoisting that took place in the school, by our Principals. Children spoke about topics related to India, namely Famous food of India, Festivals of India and Places in India. The talk by children was compiled as a video, with added colours by welcoming the gathering, the national song and expressing our gratitude. Children are the nation builders, to nurture the feeling of patriotism and oneness an awareness campaign was initiated. Quiz, fascinating facts, details about monuments of India, story around how to become responsible and independent were presented The information thus, shared has kindled the interest and an urge in children to learn more and share it during online classes.<br />
                <br />Onam is the regional festival of Kerala. It brings happiness and wellness in abundance. Activities of making a badge and Pookalam were engrossing and enriching experiences for children. They got an opportunity to become aware of our rich cultural heritage and feel proud. The girls were dressed in the traditional attire representing Onam and boys in any one of the tricolours displaying the pride of the nation. The festivities multiplied with the Principals visit to the classes and their address brought happiness amongst everyone present.<br />
              </Typography>
            </Typography>
            {/* <Button
              size={'large'}
              variant={'outlined'}
              sx={{ marginTop: { xs: 2, md: 0 } }}
            >
              Log out
            </Button> */}
          </Box>
          {/* <Box paddingY={4}>
            <Divider />
          </Box> */}
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Current password
                </Typography> */}
                {/* <TextField
                  variant="outlined"
                  name={'currentPassword'}
                  type={'password'}
                  fullWidth
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.currentPassword &&
                    Boolean(formik.errors.currentPassword)
                  }
                  helperText={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                  }
                /> */}
              </Grid>
              <Grid item xs={12}>
                <Grid item container justifyContent={'center'} xs={12} md={6}>
                  <Box height={1} width={1} maxWidth={500}>
                    <Box
                      component={'img'}
                      src={
                        'https://www.npsypr.com/gallery/gallery/Deeksha.jpg'
                      }
                      width={1}
                      height={1}
                      sx={{
                        filter:
                         theme.palette.mode === 'dark'
                           ? 'brightness(0.8)'
                           : 'none',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/* <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Repeat password
                </Typography> */}
                {/* <TextField
                  variant="outlined"
                  name={'repeatPassword'}
                  type={'password'}
                  fullWidth
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.repeatPassword &&
                    Boolean(formik.errors.repeatPassword)
                  }
                  helperText={
                    formik.touched.repeatPassword &&
                    formik.errors.repeatPassword
                  }
                /> */}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch color="primary" defaultChecked />}
                  label={
                    <Typography variant="subtitle1" fontWeight={700}>
                      Public Profile
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch color="primary" />}
                  label={
                    <Typography variant="subtitle1" fontWeight={700}>
                      Expose your email
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid> */}
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'center' }}
                  justifyContent={'space-between'}
                  width={1}
                  margin={'0 auto'}
                >
                  {/* <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={'subtitle2'}>
                      You may also consider to update your{' '}
                      <Link
                        color={'primary'}
                        href={'/events-show-and-tell-activity'}
                        underline={'none'}
                      >
                        notification settings.
                      </Link>
                    </Typography>
                  </Box> */}
                  {/* <Button size={'large'} variant={'contained'} type={'submit'}>
                    Save
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Page>
    </Main>
  );
};

export default Billing;